import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";

const AboutP = () => {
  return (
    <Layout>
      <SEO title="404" canonical={`${useLocation().host}`} />

      <div className="rts-404-area rts-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="404wrapper text-center">
                <div className="thumbnail">
                  <img src="/images/contact/shape/404.png" alt="" />
                </div>
                <h2 className="title mt--40">Oops! Nothing Was Found</h2>
                <p className="disc">
                  Sorry, we couldn’t find the page you where looking for. We
                  suggest <br /> that you return to homepage.
                </p>
                <a className="rts-btn btn-primary" href="/">
                  Back To Homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutP;
